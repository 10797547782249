<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="isLoading"
    routeback="/fornecedor/cadastro/imagens-produto"
  >
    <h4 class="text-center">Selecione o arquivo</h4>
    <LineDivider></LineDivider>
    <v-row no-gutters>
      <h4>Importar por</h4>
    </v-row>
    <v-row no-gutters>
      <FormRadioGroup
        v-model="form.tipoImportacao"
        inline
        :radios="tipoImportacao"
      />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="ml-n2 mb-4 primario--text" size="20"
            >far fa-question-circle</v-icon
          >
        </template>
        Para utilizar esta opção o nome da imagem deve estar exatamente igual ao
        informado na coluna Código interno na seção Cadastros > Produtos.
      </v-tooltip>
    </v-row>
    <v-row no-gutters v-if="form.tipoImportacao === 'CODIGO_INTERNO'">
      <FormSwitch
        v-model="form.alterarSeparador"
        label="Tratar ' - ' como ' | '"
      />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="ml-3 mb-3 primario--text" size="20"
            >far fa-question-circle</v-icon
          >
        </template>
        Se você utiliza o Windows, para vincular a imagem do produto é
        necessário substituir o código interno gerado com o sinal “ | ” dentro
        do backoffice, na seção Cadastros > Produtos, pelo sinal hífen “ - ”
      </v-tooltip>
    </v-row>
    <FormFile
      label="Arquivo"
      v-model="form.nameCurrentFile"
      @file="onSelectFile"
    >
      <template v-slot:help>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="ml-3">far fa-question-circle</v-icon>
          </template>
          Formatos aceitos: .zip | .jpeg | .jpg | .png | .webp
        </v-tooltip>
      </template>
    </FormFile>
    <v-row class="mb-10">
      <v-col cols="12" md="6">
        <h4 class="text-center mb-5">Recomendações</h4>
        <ul>
          <li>
            São aceitos arquivos nos formatos: <b>zip, jpeg, jpg, png, webp</b>
          </li>
          <li>
            Devem seguir o padrão de nomenclatura
            <b>CODIGOBARRAS.JPG</b> (Ex:789654321.jpg)
          </li>
          <li>Submeta um arquivo <b>.zip</b> com até 100Mb</li>
          <li>Até 1Mb por imagem</li>
          <li>Formatos .jpg, .jpeg, .png ou .webp</li>
          <li>Preferivelmente quadrada</li>
          <li>Preferivelmente fundo branco</li>
          <li>
            Para importar mais de uma imagem, adicione um número sequencial ao
            final <br />Exemplo: 4321(2).jpg
          </li>
          <li>
            Ao menos uma imagem deve estar sem esse numerador <br />Exemplo:
            4321.jpg e 4321(1).jpg juntas
          </li>
        </ul>
      </v-col>
      <v-col cols="12" md="6">
        <h4 class="text-center mb-5">Como montar o zip</h4>
        <img src="@/assets/exemplo_zipando.png" style="width: 100%" />
      </v-col>
    </v-row>
    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import LineDivider from '@/components/LineDivider'
import FormButton from '@/components/form/FormButton'
import FormFile from '@/components/form/FormFile'
import FormRadioGroup from '@/components/form/FormRadioGroup'
import FormSwitch from '@/components/form/FormSwitch'
import { mapGetters } from 'vuex'
import {
  IMAGEM_PRODUTO_UPLOAD,
  TIPO_IMPORTACAO,
  TYPES_ACCEPTED
} from '@/store/actions/imagemProduto'

export default {
  name: 'CalendarioForm',
  components: {
    SidePopup,
    LineDivider,
    FormButton,
    FormFile,
    FormRadioGroup,
    FormSwitch
  },
  data: () => ({
    isLoading: false,
    form: {
      tipoImportacao: 'CODIGO_BARRAS',
      alterarSeparador: false
    },
    tipoImportacao: TIPO_IMPORTACAO
  }),
  methods: {
    onClose(routeBack = false) {
      this.isLoading = false
      this.$emit('close')
      if (routeBack) {
        this.$router.replace('/fornecedor/cadastro/imagens-produto')
      }
    },
    onSubmit() {
      this.isLoading = true
      this.form.nome = this.form.nameCurrentFile
        ? this.form.nameCurrentFile
        : new Date().toISOString()
      const formData = new FormData()
      formData.append('idfornecedor', this.getFornecedorId)
      formData.append('foldername', this.form.nome)
      formData.append('tipoimportacao', this.form.tipoImportacao)
      formData.append('alterarseparador', this.form.alterarSeparador)
      formData.append('file', this.form.currentFile)
      this.$store
        .dispatch(IMAGEM_PRODUTO_UPLOAD, formData)
        .then(() => {
          this.$vueOnToast.pop(
            'success',
            'Images de produtos importadas com sucesso, aguarde o processamento!'
          )
          this.onClose(true)
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Não foi possível importar as imagens de produtos'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    onSelectFile(file) {
      if (!this.getAcceptedFiles(file.type)) {
        this.$vueOnToast.pop(
          'error',
          `O formato do arquivo selecionado é inválido! (${file.type})`
        )
        this.form.nameCurrentFile = null
        return
      }
      const size = 100 * 1024 * 1024
      if (file.size > size) {
        this.$vueOnToast.pop(
          'error',
          'Arquivo superior a 100mb, selecione um arquivo menor!'
        )
      }
      this.form.currentFile = file
    },
    getAcceptedFiles(type) {
      const typesAccepted = TYPES_ACCEPTED
      return typesAccepted.filter(t => t == type).length > 0
    }
  },
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    title() {
      return 'Importar imagens de produtos'
    }
  },
  created() {},
  mounted() {},
  watch: {}
}
</script>

<style lang="scss"></style>
